import Amplify from '@aws-amplify/core';

const {
  REACT_APP_AMPLIFY_USER_POOL_ID,
  REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
} = process.env;

export const configureAuth = () => {
  Amplify.configure({
    Auth: {
      region: 'eu-central-1',
      userPoolId: REACT_APP_AMPLIFY_USER_POOL_ID,
      identityPoolId: 'eu-central-1:46ecbf9b-62de-49d8-988a-36ff8b29a55b',
      userPoolWebClientId: REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: false
    }
  });
};
