import { Integration } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: Integration = {
  customers: null,
  integrators: null,
  integrationData: null,
  assetData: null,
  assetDataFetching: false,
  customerData: null,
  customerDataFetching: false,
  updateAppsSubmitting: false,
  updateAppsResponse: null,
  selectedCustomer: null,
  selectedTab: null,
  filterCustomer: null,
  isFetching: false,
  isError: false
};

export default function reducer(state: Integration = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_CUSTOMERS_FETCHING:
      return {
        ...state,
        customerDataFetching: payload.state,
      };
    case ActionTypes.SET_UPDATE_APPS_SUBMITTING:
      return {
        ...state,
        updateAppsSubmitting: payload.state,
      };
    case ActionTypes.UPDATE_CUSTOMER_APPS_RESPONSE:
      return {
        ...state,
        updateAppsResponse: payload,
      };
    case ActionTypes.UPDATE_CUSTOMER_APPS:
      return {
        ...state,
        customers: state.customers?.map((customer) => {
          if (customer.customer === payload.customerId) {
            return {
              ...customer,
              apps: payload.apps,
            }
          } else return customer;
        })
      }
    case ActionTypes.SET_CUSTOMER_DATA:
      return {
        ...state,
        customerData: payload,
      };
    case ActionTypes.SET_INTEGRATION_DATA:
      return {
        ...state,
        customers: null,
        integrationData: payload,
      };
    case ActionTypes.SET_ASSET_DATA:
      return {
        ...state,
        assetData: payload,
      };
    case ActionTypes.SET_ASSET_DATA_FETCHING:
      return {
        ...state,
        assetDataFetching: payload.state,
      };
    case ActionTypes.SET_INTEGRATORS:
      return {
        ...state,
        integrators: payload,
      };
    case ActionTypes.SET_CUSTOMERS:
      return {
        ...state,
        customers: payload,
      };
    case ActionTypes.ADD_INTEGRATION:
      return {
        ...state,
        customers: state.customers?.map(customer => {
          if (customer.customer === payload.customer.customer) {
            return { ...customer, integrations: [...customer.integrations, payload.integrator] };
          } else return customer;
        }),
      };
    case ActionTypes.SET_CUSTOMER:
      return {
        ...state,
        filterCustomer: state.customers?.find(customer => customer.customer === payload)
      };
    case ActionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        selectedTab: payload,
      };
    case ActionTypes.SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: state.customers?.find(customer => customer.customer === payload)
      };
    case ActionTypes.RESET_CUSTOMERS:
      return {
        ...state,
        customers: null,
      };

    // case 'RECEIVE_ERROR':
    //   return { ...state, isError: true, isFetching: false };
    // case 'UPDATE_ERROR':
    //   return { ...state, isError: true, isFetching: false };
    // case 'DELETE_ERROR':
    //   return { ...state, isError: true, isFetching: false };
    default:
      return state;
  }
}
