enum ActionTypes {
  AWS_CONNECT,
  AWS_SET_CONNECTED,
  MQTT_ERROR,
  SET_DEVICE_STRING,
  CLEAR_MESSAGES,
  SET_PAUSED,
  SET_UNPAUSED,
  SAVE_DEVICES,
  SAVE_DEVICES_ERROR,
  SAVE_DEVICES_FETCHING,
  ADD_MESSAGE,
  SET_EVENT_STRING,
  SET_EVENT_SCHEMA,
  SET_EVENT_SCHEMAS,
  ADD_TO_EVENT_LIST,
  SET_FEATURES,
  SET_FEATURES_SUBMITTING,
  UPDATE_FEATURES_FOR_CUSTOMER,
  SET_GEOCOUPLING,
  SET_GEOCOUPLING_CUSTOMER,
  ADD_GEOCOUPLING_FLEET,
  SET_CUSTOMER_DATA,
  SET_ASSET_DATA_FETCHING,
  SET_INTEGRATION_DATA,
  SET_INTEGRATORS,
  SET_CUSTOMERS,
  ADD_INTEGRATION,
  SET_CUSTOMER,
  SET_ACTIVE_TAB,
  SET_SELECTED_CUSTOMER,
  RESET_CUSTOMERS,
  SET_LOG_DEVICE_STRING,
  SET_QA_STREAMS,
  START_STREAM_CREATE,
  STREAM_CREATE_SUCCESS,
  STREAM_CREATE_ERROR,
  SET_TARGET_CUSTOMERS,
  SET_TARGET_CUSTOMER,
  SET_DEVICE_DATE_LIST,
  SET_DEVICE_DATE_LIST_ERROR,
  GET_DEVICE_DATE_LIST,
  SET_MESSAGE_INSPECTOR_DATE,
  GET_VIEW_JSON,
  SET_VIEW_JSON,
  GET_DEVICE_CUSTOMER,
  GET_DEVICE_CUSTOMER_FETCHING,
  GET_DEVICE_CUSTOMER_ERROR,
  GET_DEVICE_INSPECTOR_CUSTOMER,
  GET_DEVICE_INSPECTOR_CUSTOMER_FETCHING,
  GET_DEVICE_INSPECTOR_CUSTOMER_ERROR,
  GET_MONITORING_CHART,
  GET_MONITORING_CHART_FETCHING,
  GET_MONITORING_CHART_ERROR,
  GET_COMMANDS_FETCHING,
  SET_COMMAND_RESPONSE,
  SET_COMMANDS,
  SEND_COMMAND_SUBMITTING,
  SET_ASSET_DATA,
  SET_DEVICE_CONFIGURATIONS,
  SET_DEVICE_CONFIGURATIONS_FETCHING,
  SET_DEVICE_CONFIGURATIONS_ERROR,
  SET_DEVICE_CONFIGURATIONS_SUBMITTING,
  SET_DEVICE_CONFIGURATIONS_SUBMIT_RESPONSE,
  GET_CUSTOMERS_FETCHING,
  UPDATE_CUSTOMER_APPS,
  SET_UPDATE_APPS_SUBMITTING,
  UPDATE_CUSTOMER_APPS_RESPONSE,
  SET_IDENTITIES,
  SET_IDENTITIES_FETCHING,
  SET_CREATE_IDENTITY_SUBMITTING,
  SET_DELETE_IDENTITY_SUBMITTING,
  SET_CREATE_IDENTITY_RESPONSE,
  SET_DELETE_IDENTITY_RESPONSE,
  SET_IDENTITY_DISPLAY_RESPONSE,
  SET_ASSET_EXISTS_FOR_DEVICE,
}

export default ActionTypes;
