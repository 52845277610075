import dayjs from 'dayjs';

export const byName = (a, b) => {
  var nameA = a.name?.toUpperCase();
  var nameB = b.name?.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const byTime = (a, b) => {
  var timeA = dayjs(a.lastModified).unix();
  var timeB = dayjs(b.lastModified).unix();
  if (timeA < timeB) {
    return -1;
  }
  if (timeA > timeB) {
    return 1;
  }

  return 0;
};
