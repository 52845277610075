import { StreamMonitoring } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: StreamMonitoring = {
  urls: [],
  isFetching: false,
  isError: false
};
export default function reducer(state: StreamMonitoring = initialState, action): StreamMonitoring {
  switch (action.type) {
    case ActionTypes.GET_MONITORING_CHART:
      return {
        ...state,
        urls: action.payload,
        isFetching: false
      };
    case ActionTypes.GET_MONITORING_CHART_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case ActionTypes.GET_MONITORING_CHART_ERROR:
      return {
        ...state,
        urls: [],
        isFetching: false,
        isError: action.payload
      };
    default:
      return state;
  }
}
