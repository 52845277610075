import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { UserLevel } from 'common/constants';

export const checkUserGroupAuthorised = (route, adminUser, serviceUser, readOnlyUser) => {
  route.excludeFromUserGroups = route.excludeFromUserGroups || [];

  if (adminUser) {
    if (!route.excludeFromUserGroups.includes(UserLevel.ADMIN)) return route;
  } else if (serviceUser) {
    if (!route.excludeFromUserGroups.includes(UserLevel.SERVICE)) return route;
  } else if (readOnlyUser) {
    if (!route.excludeFromUserGroups.includes(UserLevel.READ_ONLY)) return route;
  }

  return false;
};

const checkRoute = (route, subMenus, admin, serviceUser, readOnlyUser) => {
  route.excludeFromUserGroups = route.excludeFromUserGroups || [];

  if (route.menuItem?.parent) {
    const parent = subMenus.find(subMenu => subMenu.id === route.menuItem.parent);
    route.disabled = parent.disabled || !checkUserGroupAuthorised(parent, admin, serviceUser, readOnlyUser);
  }

  if (checkUserGroupAuthorised(route, admin, serviceUser, readOnlyUser)) return route;

  return {
    ...route,
    disabled: true,
  };
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
      <p>If this problem persists please contact an administrator</p>
    </div>
  );
};

const WaitingComponent = Component => {
  return props => (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Need to add more graceful error handling
        window.location.reload();
      }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default function RouteNavigator({ appRoutes, subMenus, admin, serviceUser, readOnlyUser}) {
  const routes = appRoutes.map(route => checkRoute(route, subMenus, admin, serviceUser, readOnlyUser));
  return (
    <div style={{ textAlign: 'center' }}>
      <Switch>
        {routes.map(route =>
          route.redirect ? (
            <Redirect key={route.path} to={route.redirect} exact={route.exact} />
          ) : (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={WaitingComponent(!route.disabled ? route.component : routes[routes.length - 1].component)}
            />
          )
        )}
        <Route component={WaitingComponent(routes[routes.length - 1].component)} />
      </Switch>
    </div>
  );
}
