import React from 'react';
import { Spin } from 'antd';
import { useAuth } from 'auth/useAuth';
import { configureAuth } from 'auth/configureAmplify';
import { UserState } from '../Interfaces';

configureAuth();

const AuthContext = React.createContext<UserState | null>(null);

const AuthProvider = ({ children }) => {
  const currentURL = window.location.href;
  const {
    state: { userInfo, credentials, tokenLoading, token },
    handleSignout
  } = useAuth(currentURL);
  const isAdmin = userInfo?.permissions.find(permission => permission === 'Administrator');
  const isServiceUser = userInfo?.permissions.find(permission => permission === 'service');
  const isReadOnlyUser = userInfo?.permissions.find(permission => permission === 'Read only');

  return (
    <AuthContext.Provider value={{ info: userInfo, credentials, signout: handleSignout, isAdmin, isServiceUser, isReadOnlyUser, token }}>
      {tokenLoading || !userInfo ? (
        <div style={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export function useUserState() {
  const state = React.useContext(AuthContext);
  return state;
}

export default AuthProvider;
