import { combineReducers } from 'redux';
import integration from './featureReducers/integration.reducer';
import features from './featureReducers/features.reducer';
import geoCoupling from './featureReducers/geoCoupling.reducer';
import stream from './featureReducers/stream.reducer';
import qaStream from './featureReducers/qaStream.reducer';
import logTracker from './featureReducers/logTracker.reducer';
import messageInspector from './featureReducers/messageInspector.reducer';
import events from './featureReducers/event.reducer';
import streamMonitoring from './featureReducers/streamMonitoring.reducer';
import commands from './featureReducers/commands.reducer';
import deviceConfiguration from './featureReducers/deviceConfiguration.reducer';
import identityCreator from './featureReducers/identityCreator.reducer';

export default combineReducers({
  stream,
  qaStream,
  integration,
  logTracker,
  messageInspector,
  features,
  geoCoupling,
  events,
  streamMonitoring,
  commands,
  deviceConfiguration,
  identityCreator,
});
