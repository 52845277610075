import React from 'react';
const EventCreatorContainer = React.lazy(() => import('components/eventCreator/eventCreatorContainer'));
const EditCustomerContainer = React.lazy(() => import('components/editCustomer/editCustomerContainer'));
const FeatureContainer = React.lazy(() => import('components/features/featuresContainer'));
const LogTrackerContainer = React.lazy(() => import('components/logTracker/logTrackerContainer'));
const IntegrationContainer = React.lazy(() => import('components/integration/integrationContainer'));
const StreamContainer = React.lazy(() => import('components/stream/streamContainer'));
const MessageInspectorContainer = React.lazy(() => import('components/messageInspector/messageInspectorContainer'));
const GeoCouplingContainer = React.lazy(() => import('components/geoCoupling/geoCouplingContainer'));
const QaStreamContainer = React.lazy(() => import('components/qaStream/qaStreamContainer'));
const StreamMonitoringContainer = React.lazy(() => import('components/streamMonitoring/streamMonitoringContainer'));
const CommandsPage = React.lazy(() => import('components/commands/commandsContainer'));
const CommandsHeroicModePage = React.lazy(() => import('components/commandsHeroicMode/commandsContainer'));
const DeviceConfigurationPage = React.lazy(() => import('components/deviceConfiguration/deviceConfigurationContainer'));
const CustomerAssetsPage = React.lazy(() => import('components/customerAssets/customerAssetsContainer'));
const AppLinkPage = React.lazy(() => import('components/appLink/appLinkContainer'));
const IdentityCreatorPage = React.lazy(() => import('components/identityCreator/identityCreatorContainer'));
const NotFoundPage = React.lazy(() => import('pages/notFoundPage/notFound.page'));
const DashboardPage = React.lazy(() => import('pages/dashboard/dashboard.page'));

const pages = {
  Stream: StreamContainer,
  CreateEvents: EventCreatorContainer,
  EditCustomer: EditCustomerContainer,
  Feature: FeatureContainer,
  Integration: IntegrationContainer,
  LogTracker: LogTrackerContainer,
  MessageInspector: MessageInspectorContainer,
  QaStream: QaStreamContainer,
  GeoCoupling: GeoCouplingContainer,
  StreamMonitoring: StreamMonitoringContainer,
  NotFound: NotFoundPage,
  Dashboard: DashboardPage,
  Commands: CommandsPage,
  CommandsHeroicMode: CommandsHeroicModePage,
  CustomerAssets: CustomerAssetsPage,
  DeviceConfiguration: DeviceConfigurationPage,
  AppLink: AppLinkPage,
  IdentityCreator: IdentityCreatorPage,
};

export default pages;
