import { GeoCoupling } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState = {
  geoCouplingCustomers: null,
  filterCustomer: null
};

export default function reducer(state: GeoCoupling = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_GEOCOUPLING:
      return {
        ...state,
        geoCouplingCustomers: action.payload
      };
    case ActionTypes.SET_GEOCOUPLING_CUSTOMER:
      const selectedCustomer = state.geoCouplingCustomers?.find(customer => customer.fleetCode.toString() === action.payload);
      return {
        ...state,
        filterCustomer: selectedCustomer && {
          ...selectedCustomer,
          name: selectedCustomer.fleetName,
          customer: selectedCustomer.fleetName,
          key: selectedCustomer.fleetCode
        }
      };
    default:
      return state;
  }
}
