import { DeviceConfiguration } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: DeviceConfiguration = {
  deviceConfigurations: null,
  submitResponse: null,
  isSubmitting: false,
  isFetching: false,
};

export default function reducer(state: DeviceConfiguration = initialState, action): DeviceConfiguration {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_DEVICE_CONFIGURATIONS:
      return {
        ...state,
        isFetching: false,
        deviceConfigurations: payload,
      };
    case ActionTypes.SET_DEVICE_CONFIGURATIONS_FETCHING:
      return {
        ...state,
        isFetching: true,
        deviceConfigurations: null,
      };
    case ActionTypes.SET_DEVICE_CONFIGURATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case ActionTypes.SET_DEVICE_CONFIGURATIONS_SUBMITTING:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.SET_DEVICE_CONFIGURATIONS_SUBMIT_RESPONSE:
      return {
        ...state,
        isSubmitting: false,
        submitResponse: payload,
      };
    default:
      return state;
  }
}
