import { IdentityCreatorState } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: IdentityCreatorState = {
  createIdentityResponse: undefined,
  deleteIdentityResponse: undefined,
  displayResponse: undefined,
  identities: undefined,
  isCreateIdentitySubmitting: false,
  isDeleteIdentitySubmitting: false,
  isIdentitiesFetching: false,
};

export default function reducer(state: IdentityCreatorState = initialState, action): IdentityCreatorState {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_IDENTITIES_FETCHING:
      return {
        ...state,
        isIdentitiesFetching: payload,
      };
    case ActionTypes.SET_CREATE_IDENTITY_SUBMITTING:
      return {
        ...state,
        isCreateIdentitySubmitting: payload,
      };
    case ActionTypes.SET_DELETE_IDENTITY_SUBMITTING:
      return {
        ...state,
        isDeleteIdentitySubmitting: payload,
      };
    case ActionTypes.SET_IDENTITIES:
      return {
        ...state,
        identities: payload,
      };
    case ActionTypes.SET_CREATE_IDENTITY_RESPONSE:
      return {
        ...state,
        createIdentityResponse: payload,
      };
    case ActionTypes.SET_DELETE_IDENTITY_RESPONSE:
      return {
        ...state,
        deleteIdentityResponse: payload,
      };
    case ActionTypes.SET_IDENTITY_DISPLAY_RESPONSE:
      return {
        ...state,
        displayResponse: payload,
      };
    default:
      return state;
  }
}
