import React, { useEffect } from 'react';
import SideBar from './components/utils/sideBar/sideBar';
import RouteNavigator from './navigation/routeNavigator';
import appRoutes, { subMenus } from './navigation/appRoutes';
import { useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'state/store';
import { useUserState } from 'auth/Auth.provider';
import './App.css';
import 'antd/dist/antd.css';
import { MenuItem } from 'Interfaces';

const App = () => {
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);
  const history = useHistory();
  const user = useUserState();

  useEffect(() => {
    setMenuItems(
      appRoutes
        .filter(route => route.menuItem && !route.hidden)
        .map(route => {
          return {
            ...route.menuItem,
            path: route.path,
            disabled: route.disabled,
            excludeFromUserGroups: route.excludeFromUserGroups || [],
          } as MenuItem;
        })
    );
  }, [setMenuItems]);

  return (
    <Provider store={store}>
      <SideBar
        username={user?.info?.name}
        applicationTitle='Service Application'
        menuItems={menuItems}
        signOut={user.signout}
        history={history}
        subMenus={subMenus}
        admin={user.isAdmin}
        serviceUser={user.isServiceUser}
        readOnlyUser={user.isReadOnlyUser}
      >
        <RouteNavigator
          appRoutes={appRoutes}
          subMenus={subMenus}
          admin={user.isAdmin}
          serviceUser={user.isServiceUser}
          readOnlyUser={user.isReadOnlyUser}
        />
      </SideBar>
    </Provider>
  );
};

export default App;
