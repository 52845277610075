import { DeviceStream } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const maxArrInMem = 199;

const initialState: DeviceStream = {
  messages: [],
  filterDevice: '',
  deviceCustomerDetails: null,
  devices: [],
  connected: false,
  paused: false,
  assetExistsForDevice: false,
  isFetching: false,
  isError: false
};

export default function reducer(state: DeviceStream = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case ActionTypes.ADD_MESSAGE:
      return {
        ...state,
        messages: [payload, ...state.messages].slice(0, maxArrInMem)
      };
    case ActionTypes.SET_DEVICE_STRING:
      return {
        ...state,
        filterDevice: payload,
        deviceCustomerDetails: null
      };
    case ActionTypes.SAVE_DEVICES:
      return {
        ...state,
        devices: payload
      };
    case ActionTypes.SAVE_DEVICES_ERROR:
      return {
        ...state,
        isError: payload
      };
    case ActionTypes.SAVE_DEVICES_FETCHING:
      return {
        ...state,
        isFetching: payload
      };
    case ActionTypes.CLEAR_MESSAGES:
      return {
        ...state,
        messages: []
      };
    case ActionTypes.SET_PAUSED:
      return {
        ...state,
        paused: true
      };
    case ActionTypes.SET_UNPAUSED:
      return {
        ...state,
        paused: false
      };
    case ActionTypes.GET_DEVICE_CUSTOMER:
      return {
        ...state,
        deviceCustomerDetails: payload,
        isFetching: false,
        isError: false
      };
    case ActionTypes.GET_DEVICE_CUSTOMER_FETCHING:
      return {
        ...state,
        deviceCustomerDetails: null,
        isFetching: true,
        isError: false
      };
    case ActionTypes.GET_DEVICE_CUSTOMER_ERROR:
      return {
        ...state,
        deviceCustomerDetails: null,
        isFetching: false,
        isError: true
      };
    case ActionTypes.AWS_SET_CONNECTED:
      return {
        ...state,
        connected: payload
      };
    case ActionTypes.SET_ASSET_EXISTS_FOR_DEVICE:
      return {
        ...state,
        assetExistsForDevice: payload,
      };
    case ActionTypes.MQTT_ERROR:
      console.error(payload);
      return {
        ...state
      };
    default:
      return state;
  }
}
