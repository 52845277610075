import { LogTracker } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: LogTracker = {
  filterDevice: '',
  devicesWithLogs: [
    {
      deviceId: 'SSD000000000087',
      logs: [
        {
          log: 'test 1',
          size: '32kb',
          link: 'test',
          key: 'test 1',
          startTime: new Date(),
          logTime: '6',
          completed: true
        },
        {
          log: 'test 2',
          size: '32kb',
          link: 'test',
          key: 'test 2',
          startTime: new Date(),
          logTime: '12',
          completed: false
        }
      ]
    }
  ],
  isFetching: false,
  isError: false
};

export default function reducer(state: LogTracker = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LOG_DEVICE_STRING:
      return {
        ...state,
        filterDevice: action.payload
      };
    default:
      return state;
  }
}
