import { QaStream } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: QaStream = {
  streams: [],
  targetCustomers: [],
  selectedTargetCustomer: null,
  isFetching: false,
  isError: false
};

export default function reducer(state: QaStream = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_QA_STREAMS:
      return {
        ...state,
        streams: action.payload
      };
    case ActionTypes.START_STREAM_CREATE:
      return { ...state, isError: false, isFetching: true };
    case ActionTypes.STREAM_CREATE_SUCCESS:
      return { ...state, isError: false, isFetching: false };
    case ActionTypes.STREAM_CREATE_ERROR:
      return { ...state, isError: action.payload, isFetching: false };
    case ActionTypes.SET_TARGET_CUSTOMERS:
      return {
        ...state,
        targetCustomers: action.payload
      };
    case ActionTypes.SET_TARGET_CUSTOMER:
      return {
        ...state,
        selectedTargetCustomer: state.targetCustomers?.find(customer => customer.code === action.payload)
      };
    default:
      return state;
  }
}
