import React from 'react';
import pages from 'pages/pages';

import {
  ApiOutlined,
  AppstoreAddOutlined,
  CarOutlined,
  CloudUploadOutlined,
  CodeOutlined,
  ExperimentOutlined,
  FileSearchOutlined,
  ForkOutlined,
  GlobalOutlined,
  HomeOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  PartitionOutlined,
  SettingOutlined,
  SolutionOutlined,
  ToolOutlined,
  BlockOutlined,
  UserAddOutlined,
} from '@ant-design/icons';

import { AppRoute, SubMenuItem } from 'Interfaces';

const { REACT_APP_ENV } = process.env;
const isProd = REACT_APP_ENV === 'production';

export const subMenus: SubMenuItem[] = [
  { name: 'Devices', id: 'devices', icon: HomeOutlined },
  { name: 'Assets', id: 'assets', icon: CarOutlined},
  { name: 'MultiDevice', id: 'multiDevice', icon: PartitionOutlined },
  { name: 'External API', id: 'xApi', icon: ApiOutlined, disabled: true },
  { name: 'App Config', id: 'appConfig', icon: ToolOutlined },
  { name: 'Tools', id: 'tools', icon: ExperimentOutlined, disabled: isProd },
];

let appRoutes: AppRoute[] = [
  {
    path: '/',
    component: pages.Stream,
    exact: true
  },
  {
    path: '/stream',
    component: pages.Stream,
    exact: true,
    menuItem: { name: <span>Device Stream</span>, parent: 'devices', icon: ImportOutlined },
    // Add this to route if you want to exclude from user
    // excludeFromUserGroups: [UserLevel.ADMIN, UserLevel.SERVICE, UserLevel.READ_ONLY],
  },
  {
    path: '/integration',
    component: pages.Integration,
    exact: true,
    menuItem: {
      name: (
        <span>
          Integration<b>[Beta]</b>
        </span>
      ),
      parent: 'multiDevice',
      icon: SolutionOutlined
    }
  },
  {
    path: '/features',
    component: pages.Feature,
    exact: true,
    menuItem: {
      name: <span>TrailerFit/BrakePlus</span>,
      parent: 'appConfig',
      icon: AppstoreAddOutlined
    }
  },
  {
    path: '/geoCoupling',
    component: pages.GeoCoupling,
    exact: true,
    menuItem: {
      name: <span>GeoCoupling/DTR</span>,
      parent: 'appConfig',
      icon: GlobalOutlined
    }
  },
  {
    path: '/inspector',
    component: pages.MessageInspector,
    exact: true,
    menuItem: { name: <span>Message Inspector</span>, parent: 'devices', icon: FileSearchOutlined }
  },
  {
    path: '/qaStream',
    component: pages.QaStream,
    exact: true,
    menuItem: {
      name: <span>QA stream</span>,
      parent: 'devices',
      icon: ForkOutlined
    }
  },
  {
    path: '/monitoring',
    component: pages.StreamMonitoring,
    exact: true,
    menuItem: { name: <span>Stream monitoring</span>, parent: 'devices', icon: LineChartOutlined }
  },
  // {
  //   path: '/logs',
  //   component: pages.LogTracker,
  //   exact: true,
  //   adminReq: true,
  //   menuItem: {
  //     name: (
  //       <span>
  //         Log Tracker<b>[POC]</b>
  //       </span>
  //     ),
  //     parent: 'devices',
  //     icon: FileSyncOutlined
  //   }
  // },
  {
    path: '/editCustomer/:id',
    component: pages.EditCustomer
  },
  {
    path: '/editCustomer/',
    component: pages.EditCustomer
  },
  {
    path: '/dashboard/',
    component: pages.Dashboard
  },
  {
    path: '/commands/',
    component: pages.Commands,
    exact: true,
    menuItem: { name: <span>Commands</span>, parent: 'devices', icon: CodeOutlined },
  },
  {
    path: '/commandsHeroicMode/',
    component: pages.CommandsHeroicMode,
    exact: true,
    hidden: isProd,
    menuItem: {
      name: (<span>Commands (Heroic Mode)</span>),
      parent: 'devices',
      icon: CodeOutlined,
    },
  },
  {
    path: '/deviceConfiguration/',
    component: pages.DeviceConfiguration,
    exact: true,
    menuItem: { name: <span>Device Configuration</span>, parent: 'devices', icon: SettingOutlined }
  },
  {
    path: '/customerAssets/',
    component: pages.CustomerAssets,
    exact: true,
    menuItem: { name: <span>Customer Assets</span>, parent: 'assets', icon: InfoCircleOutlined }
  },
  {
    path: '/appLink/',
    component: pages.AppLink,
    exact: true,
    menuItem: { name: <span>Customer Apps</span>, parent: 'appConfig', icon: BlockOutlined }
  },
  {
    path: '/identityCreator/',
    component: pages.IdentityCreator,
    exact: true,
    menuItem: { name: <span>Identity Creator</span>, parent: 'appConfig', icon: UserAddOutlined }
  },
  {
    path: '/events',
    component: pages.CreateEvents,
    exact: true,
    menuItem: {
      name: (
        <span>
            Events<b>[beta]</b>
          </span>
      ),
      parent: 'tools',
      icon: CloudUploadOutlined,
    },
    hidden: isProd,
  }
];

appRoutes.push({
  path: '/login/:env/:token',
  exact: true,
  component: pages.Dashboard,
  redirect: '/'
});

appRoutes.push({
  path: '/not-found',
  component: pages.NotFound,
  exact: true
});

export default appRoutes;
