import React, { useLayoutEffect, useEffect } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RightSquareOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu, Tag, Avatar } from 'antd';
import { MenuItem, SubMenuItem } from 'Interfaces';
import './sideBar.css';
import { checkUserGroupAuthorised } from 'navigation/routeNavigator';

// antd menu type definition is missing features
const TypelessMenu = Menu as any;

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;
const phoneWidth = 768;

export interface SideBarProps {
  history: any;
  applicationTitle?: string;
  defaultDrawerClosed?: boolean;
  menuItems?: MenuItem[];
  subMenus?: SubMenuItem[];
  username?: string;
  admin?: boolean;
  serviceUser?: boolean;
  readOnlyUser?: boolean;
  signOut?: () => void;
  children?: React.ReactNode;
}

const filterMenuOptions = (menuItem, admin, serviceUser, readOnlyUser) => {
  menuItem.excludeFromUserGroups = menuItem.excludeFromUserGroups || [];

  return checkUserGroupAuthorised(menuItem, admin, serviceUser, readOnlyUser);
};

const SideBar: React.FC<SideBarProps> = ({
  applicationTitle = '',
  defaultDrawerClosed = false,
  history,
  menuItems,
  subMenus,
  username,
  signOut,
  admin,
  serviceUser,
  readOnlyUser,
  children
}: SideBarProps) => {
  const { width } = useWindowDimensions();
  const isPhone = width < phoneWidth;
  const path = history.location.pathname;
  const [menuTouched, setMenuTouched] = React.useState<boolean>();
  const [drawerClosed, setDrawerClosed] = React.useState<boolean>(defaultDrawerClosed);
  const [openMenu, setOpenMenu] = React.useState<string[]>([]);

  const closeMenuWhenDrawerClosed = () => {
    if (drawerClosed) {
      setOpenMenu([]);
    }
  };

  const toggleDrawer = (): void => {
    if (!drawerClosed) {
      setOpenMenu([]);
    } else {
      const selectedPathItem = menuItems?.find(menuItem => menuItem.path === path);
      setOpenMenu([selectedPathItem?.parent]);
    }
    setDrawerClosed(!drawerClosed);
  };

  const handleNav = (nav: string): void => {
    if (history) {
      history.push(nav);
    }
    if (drawerClosed) {
      setOpenMenu([]);
    } else if (isPhone) {
      setDrawerClosed(!drawerClosed);
    }
  };

  const toggleOpen = (id: string) => {
    if (drawerClosed) {
      setOpenMenu([id]);
    } else if (openMenu?.length && openMenu.indexOf(id) >= 0) {
      setOpenMenu(openMenu.filter(item => item !== id));
    } else {
      setOpenMenu([...openMenu, id]);
    }
    setMenuTouched(true);
  };

  const MenuListItem = (menuItem: MenuItem) => (
    <Menu.Item disabled={menuItem.disabled} key={menuItem.path} onClick={() => handleNav(menuItem.path)}>
      {menuItem.icon ? <menuItem.icon /> : <RightSquareOutlined />}
      <span>{menuItem.name}</span>
    </Menu.Item>
  );

  useEffect(() => {
    if (drawerClosed) {
      setTimeout(() => {
        setOpenMenu([]);
      }, 800);
    }
  }, [setOpenMenu, drawerClosed]);

  const getFirstPath = (menuItems: MenuItem[], subMenus: SubMenuItem[]) => {
    if (subMenus) {
      const subMenuItems = menuItems?.filter(menuItem => menuItem.parent === subMenus[0].id);
      if (subMenuItems[0]) {
        return subMenuItems[0].path;
      }
    }
    if (menuItems[0]) {
      return menuItems[0].path;
    }
    return '/';
  };

  useEffect(() => {
    if (menuItems?.length && !menuTouched) {
      const selectedPathItem = menuItems.find(menuItem => menuItem.path === path);
      if (selectedPathItem?.parent) {
        setOpenMenu([selectedPathItem.parent]);
      } else if (subMenus) {
        setOpenMenu([subMenus[0].id]);
      }
    }
  }, [menuItems, subMenus, path, setOpenMenu, menuTouched]);

  useLayoutEffect(() => {
    if (isPhone) {
      setDrawerClosed(true);
    }
  }, [isPhone, setDrawerClosed]);

  return (
    <div style={{ display: 'flex' }}>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={drawerClosed}
          collapsedWidth={isPhone ? 0 : 80}
          width={isPhone ? window.innerWidth - 80 : 220}
          style={{
            minHeight: '100vh',
            backgroundColor: '#fff',
            boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)'
          }}
        >
          <div className={drawerClosed ? 'closed-logo' : 'logo'} />
          {openMenu && (
            <TypelessMenu
              style={{ paddingBottom: 1 }}
              theme='light'
              mode='inline'
              selectedKeys={[path === '/' ? getFirstPath(menuItems, subMenus) : path]}
              openKeys={subMenus && [...openMenu]}
            >
              {!subMenus && menuItems?.map(menuItem => MenuListItem(menuItem))}
              {
                subMenus
                  ?.filter((subMenu) => filterMenuOptions(subMenu, admin, serviceUser, readOnlyUser))
                  ?.map((subMenu) => (
                    <SubMenu
                      key={subMenu.id}
                      disabled={subMenu.disabled}
                      title={
                        <span>
                          {subMenu.icon ? <subMenu.icon /> : <RightSquareOutlined />}
                          <span>{subMenu.name}</span>
                        </span>
                      }
                      onTitleClick={() => toggleOpen(subMenu.id)}
                    >
                      {
                        menuItems
                          ?.filter(menuItem => menuItem.parent === subMenu.id)
                          ?.filter(menuItem => filterMenuOptions(menuItem, admin, serviceUser, readOnlyUser))
                          ?.map(menuItem => MenuListItem(menuItem))
                      }
                    </SubMenu>
                  )
                )
              }
              {subMenus &&
                menuItems
                  ?.filter(menuItem => !menuItem.parent)
                  ?.map(menuItem => MenuListItem(menuItem))}
            </TypelessMenu>
          )}
        </Sider>
        <Layout>
          <Header
            style={{
              background: '#fff',
              padding: 0,
              width: '100%',
              boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <span>
              {drawerClosed && <MenuUnfoldOutlined className='trigger' onClick={toggleDrawer} />}
              {!drawerClosed && <MenuFoldOutlined className='trigger' onClick={toggleDrawer} />}
              <span className={isPhone ? 'app-title-phone' : null}>{applicationTitle}</span>
            </span>
            <TypelessMenu
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginRight: '15px'
              }}
              selectedKeys={[]}
              mode='horizontal'
            >
              <SubMenu
                title={
                  <span className='submenu-title-wrapper'>
                    <span className={isPhone ? 'app-title-phone' : null}>{username && <Tag>{username}</Tag>}</span>
                    <Avatar icon={<UserOutlined />} />
                  </span>
                }
              >
                <Menu.Item
                  key='setting:1'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginRight: '10px'
                  }}
                  onClick={() => signOut && signOut()}
                >
                  <span
                    style={{
                      margin: '15px'
                    }}
                  >
                    Sign Out
                  </span>{' '}
                  <LogoutOutlined />
                </Menu.Item>
              </SubMenu>
            </TypelessMenu>
          </Header>
          <Content
            style={{
              boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
              paddingBottom: '5px'
            }}
            className={isPhone ? 'app-content-phone' : 'app-content'}
            onClick={() => closeMenuWhenDrawerClosed()}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default SideBar;
