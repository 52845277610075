import { Features } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState = {
  features: null,
  isSubmitting: false,
};

/**
 * Updates or adds new entry for customer (when previously empty) to features state
 * @param features
 * @param newFeatures
 * @param customerId
 */
const updateOrAddFeaturesForCustomer = (features: any[], newFeatures: any[], customerId: string) => {
  let bFeatureForCustomerFound = false;

  features = features.map((customer) => {
    if (customer.code === customerId) {
      bFeatureForCustomerFound = true;
      return {
        ...customer,
        features: newFeatures,
      }
    }
    return customer;
  });

  if (!bFeatureForCustomerFound) {
    features.push({
      code: customerId,
      features: newFeatures,
    });
  }

  return features;
};

export default function reducer(state: Features = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_FEATURES_SUBMITTING:
      return {
        ...state,
        isSubmitting: payload.state,
      };
    case ActionTypes.UPDATE_FEATURES_FOR_CUSTOMER:
      return {
        ...state,
        features: updateOrAddFeaturesForCustomer(state.features, payload.features, payload.customerId),
      };
    case ActionTypes.SET_FEATURES:
      return {
        ...state,
        features: payload,
      };
    default:
      return state;
  }
}
