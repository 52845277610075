import { MessageInspector } from 'Interfaces';
import ActionTypes from 'state/actionTypes';
import { byTime } from 'common/sort';

const initialState: MessageInspector = {
  filterDate: null,
  messages: [],
  types: [],
  viewJSON: null,
  isFetchingGroup: { trailerpulse: false, trailerguard: false },
  isError: false,
  isFetchingDetails: false,
  isErrorDetails: false
};

export default function reducer(state: MessageInspector = initialState, action): MessageInspector {
  switch (action.type) {
    case ActionTypes.SET_DEVICE_STRING:
      return {
        ...state,
        messages: action.payload ? state.messages : [],
        types: action.payload ? state.types : []
      };
    case ActionTypes.SET_MESSAGE_INSPECTOR_DATE:
      return {
        ...state,
        filterDate: action.payload,
        messages: action.payload ? state.messages : [],
        types: action.payload ? state.types : []
      };
    case ActionTypes.GET_DEVICE_DATE_LIST:
      return {
        ...state,
        messages: [],
        types: [],
        isFetchingGroup: { trailerpulse: true, trailerguard: true }
      };
    case ActionTypes.SET_DEVICE_DATE_LIST:
      return {
        ...state,
        messages: [...state.messages, ...action.payload?.list].sort(byTime),
        types: [...new Set([...state.types, ...action.payload?.types])],
        isFetchingGroup: { ...state.isFetchingGroup, [action.payload.source]: action.payload?.complete }
      };
    case ActionTypes.SET_DEVICE_DATE_LIST_ERROR:
      return {
        ...state,
        messages: null,
        types: null,
        isError: true,
        isFetchingGroup: { ...state.isFetchingGroup, [action.payload.source]: false }
      };
    case ActionTypes.GET_VIEW_JSON:
      return {
        ...state,
        viewJSON: null
      };
    case ActionTypes.SET_VIEW_JSON:
      return {
        ...state,
        viewJSON: action.payload
      };
    case ActionTypes.GET_DEVICE_INSPECTOR_CUSTOMER:
      return {
        ...state,
        isFetchingDetails: false,
        isErrorDetails: false
      };
    case ActionTypes.GET_DEVICE_INSPECTOR_CUSTOMER_FETCHING:
      return {
        ...state,
        isFetchingDetails: true,
        isErrorDetails: false
      };
    case ActionTypes.GET_DEVICE_INSPECTOR_CUSTOMER_ERROR:
      return {
        ...state,
        isFetchingDetails: false,
        isErrorDetails: true
      };
    default:
      return state;
  }
}
