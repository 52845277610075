import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from 'state/reducers';
import { createLogger } from 'redux-logger';
import ActionTypes from 'state/actionTypes';
import { Action } from 'Interfaces';
// import toastReducer from './reducers/toast.reducer';
// import logToApiReducer from './reducers/logToApi.reducer';

const actionTypeEnumToString = (action: any): Action =>
  typeof action.type === 'number' && ActionTypes[action.type]
    ? {
        type: ActionTypes[action.type],
        payload: action.payload,
        group: action.group
      }
    : action;

const middlewares: any[] = [thunk];

if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({
    collapsed: true,
    actionTransformer: actionTypeEnumToString
  });
  middlewares.push(logger);
}
const store = createStore(reducers, applyMiddleware(...middlewares));

export default store;
