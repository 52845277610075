import { Commands } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: Commands = {
  commands: undefined,
  isError: false,
  isFetching: false,
  isSubmitting: false,
  commandResponse: undefined,
  error: [],
};

export default function reducer(state: Commands = initialState, action): Commands {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SEND_COMMAND_SUBMITTING:
      return {
        ...state,
        isSubmitting: payload.state,
      };
    case ActionTypes.GET_COMMANDS_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.SET_COMMANDS:
      return {
        ...state,
        commands: payload,
        isFetching: false,
      };
    case ActionTypes.SET_COMMAND_RESPONSE:
      return {
        ...state,
        commandResponse: payload,
      };
    default:
      return state;
  }
}
