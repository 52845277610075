type API = 'events' | 'geoCoupling' | 'qaStream' | 'qaTarget' | 'messageInspector' | 'auth' | 'main';

const {
  REACT_APP_EVENTS_URL_ROOT,
  REACT_APP_QA_URL_ROOT,
  REACT_APP_MESSAGE_URL_ROOT,
  REACT_APP_TARGET_URL_ROOT,
  REACT_APP_TARGET_API_KEY,
  REACT_APP_MD_API_KEY,
  REACT_APP_QA_API_KEY,
  REACT_APP_MESSAGE_API_KEY,
  REACT_APP_NEW_AUTH_URL_ROOT,
  REACT_APP_NEW_AUTH_API_KEY,
  REACT_APP_MAIN_ROOT
} = process.env;

const apiList = {
  events: { url: REACT_APP_EVENTS_URL_ROOT, key: REACT_APP_MD_API_KEY, auth: false },
  qaStream: { url: REACT_APP_QA_URL_ROOT, key: REACT_APP_QA_API_KEY, auth: false },
  qaTarget: { url: REACT_APP_TARGET_URL_ROOT, key: REACT_APP_TARGET_API_KEY, auth: false },
  messageInspector: { url: REACT_APP_MESSAGE_URL_ROOT, key: REACT_APP_MESSAGE_API_KEY, auth: false },
  auth: { url: REACT_APP_NEW_AUTH_URL_ROOT, key: REACT_APP_NEW_AUTH_API_KEY, auth: false },
  main: { url: REACT_APP_MAIN_ROOT, key: null, auth: true }
};

const defaultHeaders = {
  'Content-Type': 'application/json'
};

const handleResponse = async (response) => {
  if (response.status === 200 || response.status === 201) {
    return response.json();
  }

  const errorResponse = await response.json();
  const errorMessage = `${response.status}: ${errorResponse.Error?.message}`;
  console.warn(errorMessage);
  throw Error(errorMessage);
};

const auth = async (endpoint: string, token?: string, env?: string, headers?: Headers, options?: object) => {
  return await fetch(apiList['auth'].url + endpoint + `${env}.${token}`, {
    method: 'GET',
    headers: {
      ...defaultHeaders,
      'x-api-key': apiList['auth'].key,
      ...headers
    },
    ...options
  }).then(response => {
    return response.json();
  });
};

const get = async (api: API, endpoint: string, headers?: Headers, options?: object) => {
  const accessToken = JSON.parse(window.localStorage.getItem('token'));
  return await fetch(apiList[api].url + endpoint, {
    method: 'GET',
    headers: {
      ...defaultHeaders,
      'x-api-key': apiList[api].key,
      Authorization: apiList[api].auth ? accessToken : null,
      ...headers
    },
    ...options
  }).then(response => {
    return handleResponse(response);
  });
};

const del = async (api: API, endpoint: string, headers?: Headers, options?: object) => {
  const accessToken = JSON.parse(window.localStorage.getItem('token'));
  return await fetch(apiList[api].url + endpoint, {
    method: 'DELETE',
    headers: {
      ...defaultHeaders,
      'x-api-key': apiList[api].key,
      Authorization: apiList[api].auth ? accessToken : null,
      ...headers
    },
    ...options
  }).then(response => {
    return response.json();
  });
};

const post = async (api: string, endpoint: string, body: any, headers?: Headers, options?: object) => {
  const accessToken = JSON.parse(window.localStorage.getItem('token'));
  return await fetch(apiList[api].url + endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...defaultHeaders,
      'x-api-key': apiList[api].key,
      Authorization: apiList[api].auth ? accessToken : null,
      ...headers
    },
    ...options
  }).then(response => {
    return handleResponse(response);
  });
};

const put = async (api: string, endpoint: string, body: any, headers?: Headers, options?: object) => {
  const accessToken = JSON.parse(window.localStorage.getItem('token'));
  return await fetch(apiList[api].url + endpoint, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      ...defaultHeaders,
      'x-api-key': apiList[api].key,
      Authorization: apiList[api].auth ? accessToken : null,
      ...headers
    },
    ...options
  }).then(response => {
    return handleResponse(response);
  });
};

const patch = async (api: string, endpoint: string, body?: any, headers?: Headers, options?: object) => {
  const accessToken = JSON.parse(window.localStorage.getItem('token'));
  return await fetch(apiList[api].url + endpoint, {
    method: 'PATCH',
    body: body && JSON.stringify(body),
    headers: {
      ...defaultHeaders,
      'x-api-key': apiList[api].key,
      Authorization: apiList[api].auth ? accessToken : null,
      ...headers
    },
    ...options
  }).then(response => {
    return handleResponse(response);
  });
};

const download = (api: API, endpoint: string, filename: string, headers?: Headers, options?: object) => {
  return fetch(apiList[api].url + endpoint, {
    method: 'GET',
    headers: {
      ...defaultHeaders,
      'x-api-key': apiList[api].key,
      ...headers
    },
    ...options
  }).then(response => {
    const blob = response.blob();
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return response.json();
  });
};

const api = {
  get,
  del,
  post,
  patch,
  put,
  auth,
  download
};

export default api;
