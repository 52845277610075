import { Events } from 'Interfaces';
import ActionTypes from 'state/actionTypes';

const initialState: Events = {
  filterEvent: '',
  events: [],
  eventList: [],
  selectedEvent: null,
  isFetching: false,
  isError: false
};

export default function reducer(state: Events = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_EVENT_STRING:
      return {
        ...state,
        filterEvent: action.payload
      };
    case ActionTypes.SET_EVENT_SCHEMAS:
      return {
        ...state,
        events: action.payload
      };
    case ActionTypes.SET_EVENT_SCHEMA:
      return {
        ...state,
        selectedEvent: action.payload
      };
    case ActionTypes.ADD_TO_EVENT_LIST:
      return {
        ...state,
        eventList: [...state.eventList, action.payload]
      };
    default:
      return state;
  }
}
