export const featureOptions = [
  'track_trace', 'EBPMS', 'ODR', 'TPMS', 'disable_selectiontool',
  'livemapFCF', 'GOHC', 'TPMS_report', 'grote_guardian', 'hendrickson_watchman',
]; //showLiveMapSelectionTool

export enum UserLevel {
  READ_ONLY,
  SERVICE,
  ADMIN,
}

export enum NotificationTypes {
 SUCCESS,
 ERROR,
 SUBMIT,
}

// List of apps a customer can have access to
export const customerApps = {
  dev: [
    'geofenceconnector', 'asset-analytics',
    'sky-dev', 'sky-qa', 'sky-dev-with-ebs', 'sky-qa-with-ebs',
    'odrweb', 'odrweb-staging', 'odrweb-tg','checkmyinstallations',
    'rio', 'externalapi', 'goodyearstream', 'goodyearsampling', 'noregon', 'noregoninternal',
    'customer-module', 'odrweb_ebpms',
  ],
  prod: [
    'sky-ft', 'sky-a', 'sky-b', 'sky-ft-with-ebs', 'sky-a-with-ebs', 'sky-b-with-ebs',
    'odrweb', 'odrweb-tg', 'checkmyinstallations', 'rio',
    'externalapi', 'customer-module', 'asset-analytics', 'goodyearstream', 'odrweb_ebpms',
  ],
};
