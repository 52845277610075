import { useReducer, useEffect } from 'react';
import api from 'common/api';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const {
  REACT_APP_AUTH_REDIRECT_URL_QA,
  REACT_APP_AUTH_REDIRECT_URL_FT,
  REACT_APP_AUTH_REDIRECT_URL_PA,
  REACT_APP_AUTH_REDIRECT_URL_PB,
  REACT_APP_AUTH_REDIRECT_URL_FCS
} = process.env;

function redirectToPortal(env) {
  if (env) {
    if (env === 'qa') {
      window.location.href = REACT_APP_AUTH_REDIRECT_URL_QA;
    } else if (env === 'pa') {
      window.location.href = REACT_APP_AUTH_REDIRECT_URL_PA;
    } else if (env === 'pb') {
      window.location.href = REACT_APP_AUTH_REDIRECT_URL_PB;
    } else if (env === 'ft') {
      window.location.href = REACT_APP_AUTH_REDIRECT_URL_FT;
    } else if (env === 'fcs') {
      window.location.href = REACT_APP_AUTH_REDIRECT_URL_FCS;
    } else {
      window.location.href = REACT_APP_AUTH_REDIRECT_URL_PB;
    }
  } else {
    window.location.href = REACT_APP_AUTH_REDIRECT_URL_PB;
  }
}

const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
const RESET_USER_DATA = 'RESET_USER_DATA';
const FETCH_USER_INFO_INIT = 'FETCH_MYTRANSICS_INIT';
const FETCH_USER_INFO_SUCCESS = 'FETCH_MYTRANSICS_SUCCESS';

const authReducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER_INFO_INIT:
      return {
        ...state,
        tokenLoading: true
      };
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        tokenLoading: false,
        userInfo: action.payload.user,
        credentials: action.payload.credentials,
        token: action.payload.token
      };
    case FETCH_DATA_FAILURE:
      return { ...state, tokenLoading: false };
    case RESET_USER_DATA:
      return { ...state, userInfo: null, cognitoUser: null };
    default:
      throw new Error();
  }
};

export const useAuth = currentURL => {
  const initialState = {
    userInfo: null,
    credentials: null,
    tokenLoading: false,
    token: null
  };
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { userInfo } = state;

  const handleUserInfo = async (decodedData, accessToken) => {
    const Credentials = decodedData['https://aws.com/data'];
    const userData = decodedData['https://mt.com/data'];

    dispatch({
      type: FETCH_USER_INFO_SUCCESS,
      payload: { user: userData, credentials: Credentials, token: accessToken }
    });
  };

  useEffect(() => {
    let token;
    let env;
    if (currentURL.indexOf('login') >= 0) {
      const urlArr = currentURL.split('/');
      token = urlArr.pop();
      env = urlArr.pop();
    }
    if (env) {
      window.localStorage.setItem('env', env);
    }

    if (token && !userInfo) {
      dispatch({
        type: FETCH_USER_INFO_INIT
      });
      api
        .auth('v2/authentications/users/mytransics/tokens/', token, env)
        .then(data => {
          const accessToken = data.access_token;
          if (accessToken) {
            window.localStorage.setItem('token', JSON.stringify(accessToken));
            const decodedData = jwt_decode(accessToken);
            handleUserInfo(decodedData, accessToken);
          } else {
            console.error(data);
            dispatch({ type: FETCH_DATA_FAILURE });
            handleSignout();
          }
        })
        .catch(err => {
          console.error(err);
          dispatch({ type: FETCH_DATA_FAILURE });
          handleSignout();
        });
    } else if (!userInfo) {
      const accessToken = JSON.parse(window.localStorage.getItem('token'));

      if (accessToken) {
        const decodedData = jwt_decode(accessToken);
        const expirationTime = decodedData?.['https://aws.com/data'].Credentials.Expiration;
        if (dayjs(expirationTime) > dayjs()) {
          handleUserInfo(decodedData, accessToken);
        } else {
          handleSignout();
        }
      } else {
        handleSignout();
      }
    }
  }, [currentURL, userInfo]);

  const handleSignout = async () => {
    try {
      console.log('signed out');
      dispatch({ type: RESET_USER_DATA });
      window.localStorage.setItem('decodedData', null);
      const env = window.localStorage.getItem('env');
      redirectToPortal(env);
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };

  return { state, identityProvider: 'mytransics', handleSignout };
};
